class SdkError extends Error {
  constructor(info) {
    super('fail');
    this.name = 'SdkError';

    if (info) {
      this.info = info;
    }
  }
}

export function tradePay({ tradeNo, orderStr }) {
  return new Promise((resolve, reject) => {
    my.tradePay({
      tradeNO: tradeNo,
      orderStr,
      success: (res) => {
        console.log(res);

        resolve(res?.resultCode === '9000');
      },
      fail: (res) => {
        console.warn(res);

        reject(new SdkError(res));
      },
    });
  });
}

const temp = new Map();

export function callMiniOneWay(action, payload) {
  if (typeof my !== 'undefined') {
    my.postMessage({ action, payload });
  }
}

export function callMiniTwoWay(action, payload) {
  return new Promise((resolve, reject) => {
    temp.set(action, { resolve, reject });
    callMiniOneWay(action, payload);
  });
}

function callback({ action, payload }) {
  const tmp = temp.get(action);

  if (tmp) {
    tmp.resolve(payload);
  }
}

if (typeof my !== 'undefined') {
  my.onMessage = (event) => {
    if (event.error) {
      console.log(event);
    } else {
      console.error(event);
    }
    switch (event.action) {
      case 'login': {
        callback(event);
        break;
      }
      case 'getAuthCode': {
        callback(event);
        break;
      }
      default:
    }
  };
}
