import { smart } from 'pet-shop';

import { callMiniOneWay } from '@eaphone/logic';

import { localStore, sessionStore } from './core.js';

export { localStore, sessionStore };

export const Auth = Object.defineProperties(
  {
    clear() {
      sessionStore.clear();
      callMiniOneWay('logout');
    },
  },
  {
    token: smart(sessionStore, 'token'),
    openId: smart(sessionStore, 'openId'),
  },
);

if (import.meta.env.WATCHING) {
  Auth.token = '1fcfadfc-6367-11ef-9bf3-e79e63a13cd5';
}
