import '@eaphone/polyfill';
import '@eaphone/debugger';
import '@eaphone/style/style.scss';
import 'tailwindcss/base.css';
import 'tailwindcss/utilities.css';
import 'vant/es/dialog/style';
import 'vant/es/toast/style';

import { createApp } from 'vue';

import { loginHook } from '@eaphone/logic';
import { VantInstall } from '@eaphone/plugins/vant-install.js';
import { SentryInit } from '@eaphone/sentry';

import pkg from '../package.json';

import { router } from './pages/router.js';

import App from './app.vue';

const app = createApp(App);

router.beforeEach(loginHook.bind(router));

SentryInit({
  app,
  router,
  version: pkg.version,
});

app.use(router).use(VantInstall);

app.mount('#root');
