import { showFailToast } from 'vant';

import { Auth } from '@eaphone/storage';

import { callMiniOneWay, callMiniTwoWay, tradePay as callPay } from './lib.js';

export { callMiniOneWay, callMiniTwoWay };

export function loginHook(to, from, next) {
  if (to.meta.auth) {
    if (Auth.token) {
      next();
    } else {
      callMiniTwoWay('login')
        .then(({ token } = {}) => {
          if (!token) {
            throw new Error('授权失败');
          }

          Auth.token = token;
          next();
        })
        .catch((error) => {
          console.error(error);
          showFailToast(error.message);
          next({ name: 'error-401' });
        });
    }
  } else {
    next();
  }
}

// eslint-disable-next-line no-empty-function
export async function sdkHook() {}

export function openMap({ name, address, longitude, latitude }) {
  callMiniOneWay('openLocation', {
    longitude,
    latitude,
    name,
    address,
  });
}

export { callPay };

export function usePageConfig() {}
